export interface Globals {
    imageArr: Array<imageObj>;
}
export interface imageObj {
    _idStr: string;
    _srcStr: string;
    _linkStr: string;
    _loadedBln: boolean;
}

export abstract class GlobalRef {
    abstract get nativeGlobal(): Globals;
}

export class BrowserGlobalRef extends GlobalRef {
    get nativeGlobal(): Globals {
        return window["DATA"] as Globals;
    }
}