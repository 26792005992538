import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Headers, Response, RequestOptions} from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { RecaptchaModule } from 'ng-recaptcha';



import {NavigationComponent} from "./navigation.component";
import {FooterComponent} from "./footer.component";

import {SEOService} from './seo.service';

import {SharedModule} from './shared-module';
import {GlobalRef} from './global-ref';
import {BannerImage} from "./banner-image";
import {PreloadImage} from './preload-image.component';

import {Globals} from './globals';

declare var $: any;

@Component({
    selector: 'my-company',
    templateUrl: './company.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class CompanyComponent implements AfterViewInit {

    imagesObj: BannerImage[] =[];

    private searchURlStr = '/includes/classes/REP_SearchProcess.php';  // URL to web api

    public errorStr:string = '';

    public step2Bln: boolean = false;
    model = {
        propertyType : {value: 'Any Type'},
	    area: {value: 'All Of Peel & Halton'},
        priceRangeMin: {value: "125000"},
        priceRangeMax: {value: "100000000"},
        bedrooms: {value: "Any"},
        bathrooms: {value: "Any"},
        lotSize: {value: "Any"},
        squareFeet: {value: "Any"},
        yearBuilt: {value: "All"},
        firstName: {value: ""},
        lastName: {value: ""},
        phoneNumber: {value: ""},
        emailAddress: {value: ""}

    };

    constructor(private router: Router,
                private http: HttpClient,
                private SEOService: SEOService,
                private globals: Globals,
                _globalRef: GlobalRef,
                private _sanitizer: DomSanitizer) {
        // set the SEO details

	    SEOService.setMetaTags({_titleStr: 'Peel and Halton Luxury Real Estate - Cayman Marshall International Realty Inc.', _descriptionStr: 'Search all Luxury Peel and Halton real estate listings, cottages, homes and land - Peel and Halton Luxury Listing Agent', _urlStr: 'https://www.oakvillehomerealestate.net/company', _keywordsStr: 'Oakville luxury real estate, Mississauga luxury real estate,Peel and Halton luxury real estate, Peel and Halton luxury homes, Peel and Halton luxury single family homes, Peel and Halton luxury property, Peel and Halton property, Peel and Halton waterfront'});
        // setup the image cycle
        this.setupImageCycle({_imageArr: this.shuffleArray(_globalRef.nativeGlobal.imageArr) });

    }

    ngAfterViewInit (): void  {
        var carouselObj=($('#carousel') as any).carousel({
            interval: 5000
        });
        console.log(carouselObj);

    }

    private setupImageCycle (obj) {
        // define properties
        var imageArr=obj._imageArr;
        // iterate through the image array to add each item to the image object property
        for (var i = 0; i < imageArr.length; i++) {
            // define properties
            var imageObj=imageArr[i];
            var bannerImageObj=new BannerImage();
            bannerImageObj.idStr=i;
            bannerImageObj.nameStr=imageObj._idStr;
            bannerImageObj.valStr="/admin/thumbnail/phpThumb.php?src=/" + imageObj._srcStr + "&w=1900&h=1266"
            this.imagesObj.push(bannerImageObj);
        }
        console.log(this.imagesObj);
    }

    getBackground(image) {
        return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    }

    submitForm (id: number) {

        switch (id) {
            case 1:
                // show the second part of the search form
                this.step2Bln=true;
                break;
        }
    }

    public submitData (captchaResponse: string) {
        // submit the search data
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded;',
                'X-Requested-With': 'XMLHttpRequest'
            })
        };
        // track the even
        this.http
            .post(this.searchURlStr, $.param(this.setupJSONObject({"_captchaResponse": captchaResponse})), httpOptions)
            .toPromise()
            .then((responseObj) => {
                //console.log(response.json());
                // define properties
                var statusBln=responseObj['statusBln'];
                // fork, if there was an error then output the error
                if (!statusBln){
                    this.errorStr=responseObj['errorArr'][0];
                    // reset the recaptcha
                    let myWindow: any = window;
                    myWindow.grecaptcha.reset();
                } else {
                    if (responseObj['redirectStr']!='') window.location.href=responseObj['redirectStr'] + '&referStr=cm';
                    this.errorStr='Your information has been sent. You will be redirected to our listings shortly.';
                }
                //if (responseObj.redirectStr!='') window.location.href=responseObj.redirectStr + '&referStr=cm';
            })
            .catch(this.handleError);
    }

    setupJSONObject (obj) {
        // define properties
        var captchaResponse=obj._captchaResponse;
        var modelObj=this.model;
        var propertyTypeStr = modelObj.propertyType.value;
        var areaStr = modelObj.area.value;
        var priceRangeMinStr = modelObj.priceRangeMin.value;
        var priceRangeMaxStr = modelObj.priceRangeMax.value;
        var bedroomsStr = modelObj.bedrooms.value;
        var bathroomsStr = modelObj.bathrooms.value;
        var lotSizeStr = modelObj.lotSize.value;
        var squareFeetStr = modelObj.squareFeet.value;
        var yearBuiltStr = modelObj.yearBuilt.value;
        var firstNameStr = modelObj.firstName.value;
        var lastNameStr = modelObj.lastName.value;
        var emailAddressStr = modelObj.emailAddress.value;
        var phoneNumberStr = modelObj.phoneNumber.value;
        var ipAddrStr = this.globals.ipAddrStr;
        // create the JSON Post Object container
        var JSONObj = {
            "Property_Type": propertyTypeStr,
            "searchArea": areaStr,
            "searchMin": priceRangeMinStr,
            "searchMax": priceRangeMaxStr,
            "searchBedrooms": bedroomsStr,
            "searchBathrooms": bathroomsStr,
            "searchLotSize": lotSizeStr,
            "searchSquareFeet": squareFeetStr,
            "searchYearBuilt": yearBuiltStr,
            "firstNameStr": firstNameStr,
            "lastNameStr": lastNameStr,
            "emailStr": emailAddressStr,
            "phoneStr": phoneNumberStr,
            "ipAddrStr": ipAddrStr,
            "g-recaptcha-response": captchaResponse,
            "redirectURLStr": '/listings/dist/'

        }
        return JSONObj;
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    private shuffleArray (array) {
        var m = array.length, t, i;

        // While there remain elements to shuffle
        while (m) {
            // Pick a remaining element�
            i = Math.floor(Math.random() * m--);

            // And swap it with the current element.
            t = array[m];
            array[m] = array[i];
            array[i] = t;
        }

        return array;
    }




}