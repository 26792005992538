import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {Headers, Response, RequestOptions} from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

@Component({
    selector:'loading-image',
    template:'<img alt="foo" [src]="src" style="display: none;"/>'
})
export class PreloadImage{

    @Input() src: string;

    constructor(private http:HttpClient){
    }

    generateImage(obj: any): void {
        // define properties
        var imageURLStr=obj._imageURLStr;
        this.src = 'http://www.downgraf.com/wp-content/uploads/2014/09/01-progress.gif'; //Just a random loading gif found on google.
        this.http.get(imageURLStr,{responseType: 'blob', observe: 'response'})
            .subscribe(response => {
                let urlCreator = window.URL;
                this.src = urlCreator.createObjectURL(response.body);
            });
    }
}