import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
    emailStr: string = '';
    userIDStr: string = '';
    savedSearchIDStr: string = '';
    ipAddrStr: string = '';
	sidStr: string = '';
	presentationIDStr: string = '';
    isRegisteredBln: string;
    initbln: boolean = true;
    loginBln: boolean = true;
    debugBln: boolean = false;
    loadingBln: boolean = false;
    timeoutNum: number = 0;
    delayObj: Object = {};
    initLoadCompleteBln: boolean = true;
    limitNum: number = 10;
    startNum: number = 0;
    endNum: number = 10;
    maxPreviewNum: number = 2;
    viewedNum: number = 0;
    scrollerObj: Object;
    statusStr: string = "\"Active\", \"Sold\"";
    stateStr: string = 'ON';
    idStr: number = 1367;
    countNum: number = 0;
    propertyTypeStr: string = 'Not Specified';
    districtList: string = 'All Of Muskoka';
    searchMin: string = '125000';
    searchMax: string = '100000000';
    bedroomsNum: string = 'Any';
    bathroomsNum: string = 'Any';
    squareFeetStr: string = 'Any';

}
