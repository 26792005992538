import {NgModule, Component, ElementRef, AfterViewInit, OnInit, Injectable } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';

import {Globals} from './globals';

declare let ga: Function;

@Injectable()
@Component({
    selector: 'my-root',
    template: `
    <router-outlet scroll (scrollDown)="onScrollDown($event)" (scrollUp)="onScrollUp($event)"></router-outlet>
  `,
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit , OnInit {
    title = 'Tour of Heroes';
    private emailStr: string = '';
    private propertyTypeStr:string ='';
    private userIDStr:string = '';
    private savedSearchIDStr = '';
    private ipAddrStr = '';
    private sidStr = '';
    private statusStr = '';
    private presentationIDStr = '';
    private percent: number = 0;


    private docElem = document.documentElement
    private didScroll = false;
    private changeHeaderOn = 50;
    private initBln: boolean = true;


    constructor(private router: Router, elementRef:ElementRef, globals: Globals) {
        // define properties
        this.emailStr=elementRef.nativeElement.getAttribute('emailStr');
        this.propertyTypeStr=elementRef.nativeElement.getAttribute('propertyTypeStr');
        this.userIDStr=elementRef.nativeElement.getAttribute('userIDStr');
        this.savedSearchIDStr=elementRef.nativeElement.getAttribute('savedSearchIDStr');
        this.ipAddrStr=elementRef.nativeElement.getAttribute('ipAddrStr');
        this.sidStr=elementRef.nativeElement.getAttribute('sidStr');
        this.statusStr=elementRef.nativeElement.getAttribute('statusStr');
        this.presentationIDStr=elementRef.nativeElement.getAttribute('pidStr');
        // set the passed parameters into the globals for external reference
        if (this.emailStr!='') globals.emailStr=this.emailStr;
        if (this.propertyTypeStr!='') globals.propertyTypeStr=this.propertyTypeStr;
        if (this.userIDStr!='') globals.userIDStr=this.userIDStr;
        if (this.savedSearchIDStr!='') globals.savedSearchIDStr=this.savedSearchIDStr;
        if (this.ipAddrStr!='') globals.ipAddrStr=this.ipAddrStr;
        if (this.sidStr!='') globals.sidStr=this.sidStr;
        if (this.statusStr!='') globals.statusStr=this.statusStr;
        if (this.presentationIDStr!='') globals.presentationIDStr=this.presentationIDStr;
        // create the listener for page changes and send the google analytics data
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                console.log('logging analytics page change' + event.urlAfterRedirects);
                //ga('set', 'page', event.urlAfterRedirects);
                //ga('send', 'pageview');
                (<any>window).dataLayer.push({
                    event: 'pageview',
                    page: {
                        path: event.urlAfterRedirects
                    }
                });
            }
        });
    }

    ngOnInit (): void {
        //console.log('ngOnInit');

        // define properties
        this.router.events
            .subscribe((event) => {
            //console.log('init: ' + event);
                var header = document.querySelector( '.navbar-default' );
                var changeHeaderOn = this.changeHeaderOn;
                var sy = this.scrollY();
                // example: NavigationStart, RoutesRecognized, NavigationEnd
                //console.log(event);
                if ( sy >= changeHeaderOn ) {
                    //console.log(sy);
                    header.classList.add('navbar-shrink');
                }""
                const element = document.querySelector(".intro-heading");
                //if (element) element.scrollIntoView(element);


                window.scrollTo(0, 0);
            });

    }

    ngAfterViewInit(): void {
        //console.log('ngAfterViewInit');
        var header = document.querySelector( '.navbar-default' );
        var changeHeaderOn = this.changeHeaderOn;
        var sy = this.scrollY();
        if ( sy >= changeHeaderOn ) {
            header.classList.add('navbar-shrink');
        }
    }

    /*
    onScrollDown() {
        //console.log('scrolled down!!');
        var header = document.querySelector( '.navbar-default' );
        var changeHeaderOn = this.changeHeaderOn;
        var sy = this.scrollY();
        if ( sy >= changeHeaderOn ) {
            header.classList.add('navbar-shrink');
        }
    }

    onScrollUp() {
        var header = document.querySelector( '.navbar-default' );
        var changeHeaderOn = this.changeHeaderOn;
        var sy = this.scrollY();
        if ( sy <= changeHeaderOn ) {
            header.classList.remove('navbar-shrink');
        }
    }*/

    onScrollDown(value: number): void {
        //console.log('scrolling down');
        //console.log(value);
        // define prioerties
        var header = document.querySelector( '.navbar-default' );
        this.percent = value;
        var changeHeaderOn = this.changeHeaderOn;
        if ( value >= changeHeaderOn ) {
            header.classList.add('navbar-shrink');
        }
    }

    onScrollUp(value: number): void {
        //console.log('scrolling up');
        //console.log(value);
        // define prioerties
        var header = document.querySelector( '.navbar-default' );
        this.percent = value;
        var changeHeaderOn = this.changeHeaderOn;
        if ( value <= changeHeaderOn ) {
            header.classList.remove('navbar-shrink');
        }
    }


    scrollY() {
        return window.pageYOffset || this.docElem.scrollTop;
    }

}
