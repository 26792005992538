import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Headers, Response, RequestOptions} from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { RecaptchaModule } from 'ng-recaptcha';

import {FormGroup,FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



import {NavigationComponent} from "./navigation.component";
import {FooterComponent} from "./footer.component";

import {SharedModule} from './shared-module';
import {GlobalRef} from './global-ref';
import {BannerImage} from "./banner-image";
import {PreloadImage} from './preload-image.component';

import {Globals} from './globals';

declare var $: any;

@Component({
  selector: 'my-presentations-permalink',
  templateUrl: './presentations-permalink.component.html',
  styleUrls: ['./presentations.component.css']
})
export class PresentationsPermalinkComponent implements AfterViewInit {

  imagesObj: BannerImage[] =[];

  public isLoggedInBln:boolean=false;

  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3) ])
  });

  passwordHideBln = true;

  public isLoadedBln:boolean=false;

  private presentationSearchURLStr = '/includes/classes/REP_PresentationProcess.php';  // URL to web api

  public errorStr:string = '';

  public step2Bln: boolean = false;
  model = {
    propertyType : {value: 'Any Type'},
    area: {value: 'All Of Muskoka'},
    priceRangeMin: {value: "125000"},
    priceRangeMax: {value: "100000000"},
    bedrooms: {value: "Any"},
    bathrooms: {value: "Any"},
    lotSize: {value: "Any"},
    squareFeet: {value: "Any"},
    yearBuilt: {value: "All"},
    firstName: {value: ""},
    lastName: {value: ""},
    phoneNumber: {value: ""},
    emailAddress: {value: ""}

  };

  public presentationObj ={
    'firstNameStr': '',
    'lastNameStr': '',
    'propertyNameStr' :'',
    'urlStr': <any> null,
    'titleStr': ''
  }
  private presentationURLStr:string = '';

  constructor(private router: Router,
              private http: HttpClient,
              private globals: Globals,
              _globalRef: GlobalRef,
              private _sanitizer: DomSanitizer) {
    // setup the image cycle
    this.setupImageCycle({_imageArr: this.shuffleArray(_globalRef.nativeGlobal.imageArr) });
    // get the presentation url
    this.getPresentationURL()

  }

  ngAfterViewInit (): void  {
    var carouselObj=($('#carousel') as any).carousel({
      interval: 5000
    });
    console.log(carouselObj);

  }
  get passwordInput() { return this.signin.get('password'); }

  public passwordSubmit () {
    console.log('password submit');
    // define properties
    var passwordStr=this.signin.get('password');
    console.log(passwordStr.value);
    // fork, if the password is valid then proceed
    if (passwordStr.value=='97bcaymanmarshall') {
      console.log('password correct');
      this.isLoggedInBln=true;
    }
  }

  private getPresentationURL () {
    // submit the search data
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;',
        'X-Requested-With': 'XMLHttpRequest'
      })
    };
    // track the even
    this.http
      .post(this.presentationSearchURLStr, $.param(this.setupJSONObject()), httpOptions)
      .toPromise()
      .then((responseObj) => {
        //console.log(response.json());
        // define properties
        var statusBln=responseObj['statusBln'];
        // fork, if there was an error then output the error
        if (!statusBln){
          this.errorStr='Presentation failed to load. Please contact the administrator.';
          this.presentationObj.urlStr='';
          this.presentationObj.titleStr='';
          this.presentationObj.firstNameStr='';
          this.presentationObj.lastNameStr='';
          this.presentationObj.propertyNameStr='';
          this.isLoadedBln=true;

        } else {
          this.presentationObj.urlStr=this._sanitizer.bypassSecurityTrustResourceUrl(responseObj['urlStr']);
          this.presentationObj.titleStr=responseObj['titleStr'];
          this.presentationObj.firstNameStr=responseObj['firstNameStr'];
          this.presentationObj.lastNameStr=responseObj['lastNameStr'];
          this.presentationObj.propertyNameStr=responseObj['propertyNameStr'];
          this.isLoadedBln=true;
        }
        console.log(this.presentationObj);
        //if (responseObj.redirectStr!='') window.location.href=responseObj.redirectStr + '&referStr=cm';
      })
      .catch(this.handleError);
  }
  private setupJSONObject () {
    // define properties
    var presentationIDStr=this.globals.presentationIDStr;
    // create the JSON Post Object container
    var JSONObj = {
      "presentationIDStr": 'YcMwr9cT2BgP0H8s',

    }
    return JSONObj;
  }

  private setupImageCycle (obj) {
    // define properties
    var imageArr=obj._imageArr;
    // iterate through the image array to add each item to the image object property
    for (var i = 0; i < imageArr.length; i++) {
      // define properties
      var imageObj=imageArr[i];
      var bannerImageObj=new BannerImage();
      bannerImageObj.idStr=i;
      bannerImageObj.nameStr=imageObj._idStr;
      bannerImageObj.valStr="/admin/thumbnail/phpThumb.php?src=/" + imageObj._srcStr + "&w=1900&h=1266"
      this.imagesObj.push(bannerImageObj);
    }
    console.log(this.imagesObj);
  }

  getBackground(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  submitForm (id: number) {

    switch (id) {
      case 1:
        // show the second part of the search form
        this.step2Bln=true;
        break;
    }
  }



  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  private shuffleArray (array) {
    var m = array.length, t, i;

    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element�
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  }
}
