import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpModule} from '@angular/http';
import { HttpClientModule } from '@angular/common/http';



import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbdCarouselConfig} from './carousel-config';
//import { CollapseModule } from 'ngx-bootstrap';

import { RecaptchaModule } from 'ng-recaptcha';
import { ResponsiveModule } from 'ng2-responsive';

import { ModalModule } from 'ngx-modialog';
import { BootstrapModalModule, Modal, bootstrap4Mode } from 'ngx-modialog/plugins/bootstrap';

//import {InfiniteScrollModule} from 'ngx-infinite-scroll';


import {AssociatesService} from './associates.service';

import {IndexComponent} from './index.component';
import {AssociatesComponent} from './associates.component';
import {CompanyComponent} from './company.component';
import {BuyingComponent} from './buying.component';
import {SellingComponent} from './selling.component';
import {MagazinesComponent} from './magazines.component';
import {PresentationsComponent} from "./presentations.component";
import {PresentationsPermalinkComponent} from "./presentations-permalink.component";
import {NavigationComponent} from "./navigation.component";
import {FooterComponent} from "./footer.component";
import {PreloadImage} from './preload-image.component';
import {SEOService} from './seo.service';
import {Globals} from './globals';
import { WindowRef } from './window-ref';

import {ScrollEventDirective } from './directives/scroll.directive';



import {SharedModule} from './shared-module';
import {GlobalRef} from './global-ref';

import * as $ from 'jquery';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpModule,
	HttpClientModule,
        SharedModule.forBrowser(),
        NgbModule.forRoot(),
       //InfiniteScrollModule,
        RecaptchaModule.forRoot(),
        ResponsiveModule,
        //CollapseModule.forRoot(),
        ModalModule.forRoot(),
        BootstrapModalModule
        //InMemoryWebApiModule.forRoot(InMemoryDataService, { delay: 600 })
    ],
    declarations: [
        AppComponent,
        IndexComponent,
        AssociatesComponent,
        CompanyComponent,
        BuyingComponent,
        SellingComponent,
        MagazinesComponent,
        PresentationsComponent,
        PresentationsPermalinkComponent,
        NavigationComponent,
        FooterComponent,
        PreloadImage,
        NgbdCarouselConfig,
        ScrollEventDirective
    ],
    providers: [Globals,WindowRef,SEOService,AssociatesService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
