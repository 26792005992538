import {Component, OnInit, Input} from '@angular/core';
import {Router} from '@angular/router';
import {Headers, Response, RequestOptions} from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Globals} from './globals';

import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import * as $ from 'jquery';

@Component({
    selector: 'my-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class NavigationComponent implements OnInit {

    public isCollapsed:boolean = true;

    constructor(public router: Router,
                private http: HttpClient,
                private globals: Globals) {
    }

    ngOnInit(): void {
        //console.log('hello');


    }
    redirect(idStr) {
        this.router.navigate(['./' + idStr]);
    }

    public collapsed(event:any):void {
        //console.log(event);
    }

    public expanded(event:any):void {
        //console.log(event);
    }

    public collapseMenu () {
        //console.log('collapse menu');
        this.isCollapsed=true;
    }



}