import {NgModule, Component, ElementRef, AfterViewInit, OnInit, Input, Injectable } from '@angular/core';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';


@Injectable()
export class SEOService {


    constructor(private _title: Title, private _meta: Meta) {

    }


    setMetaTags (obj: any): void {
        // define properties
        var titleStr=obj._titleStr;
        var descriptionStr=obj._descriptionStr;
        var urlStr=obj._urlStr;
        var keywordsStr=obj._keywordsStr
        // set the meta tags
        this._title.setTitle(titleStr);
        this._meta.updateTag({ name: 'description', content: descriptionStr});
        this._meta.updateTag({ name: 'keywords', content: keywordsStr});
        this._meta.updateTag({ name: 'url', content:  urlStr });
        this._meta.updateTag({ property: 'og:Title', content: titleStr });
        this._meta.updateTag({ property: 'og:description', content: descriptionStr });
        this._meta.updateTag({ property: 'og:url', content:  urlStr });
        this._meta.updateTag({ property: 'twitter:title', content: titleStr });
        this._meta.updateTag({ property: 'twitter:description', content: descriptionStr});
        this._meta.updateTag({ property: 'twitter:card', content: 'summary_large_image' });
    }
}