import { Directive, Output, EventEmitter, HostListener, ElementRef, OnDestroy
} from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { fromEvent } from 'rxjs';

import { WindowRef } from '../window-ref';

@Directive({
    selector: '[scroll]'
})
export class ScrollEventDirective implements OnDestroy {
    @Output() scrollDown: EventEmitter<number> = new EventEmitter<number>();
    @Output() scrollUp: EventEmitter<number> = new EventEmitter<number>();

    private scrollEvent$;
    private docElem = document.documentElement
    positionNum:number= 0;

    constructor(private el: ElementRef, private winRef: WindowRef) {
        // define properties
        el = winRef.nativeWindow;
        console.log(el);
        const scrollEvent = fromEvent(window, 'scroll');
        /*this.scrollEvent$ = Observable.fromEvent(this.el.nativeElement,
            'scroll').subscribe((e: any) => {
                this.scrollPosition.emit(e.target.scrollTop);
            });*/

        this.scrollEvent$ = scrollEvent.subscribe((evt: any) => {
            var scrollYPosNum=this.scrollY();
            var positionNum=this.positionNum;
            // fork, if the scroll position is greater than the last event than it's a scroll down
            if (scrollYPosNum > positionNum) {
                this.scrollDown.emit(this.scrollY());
            } else {
                this.scrollUp.emit(this.scrollY());
            }
            // Log coords of mouse movements
            this.positionNum=scrollYPosNum;
        });
    }

    scrollY() {
        return window.pageYOffset || this.docElem.scrollTop;
    }

    ngOnDestroy() {
        this.scrollEvent$.unsubscribe();
    }
}