import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {IndexComponent} from './index.component';
import {AssociatesComponent} from './associates.component';
import {CompanyComponent} from './company.component';
import {BuyingComponent} from './buying.component';
import {SellingComponent} from './selling.component';
import {MagazinesComponent} from './magazines.component';
import {PresentationsComponent} from "./presentations.component";
import {PresentationsPermalinkComponent} from "./presentations-permalink.component";
import {Globals} from './globals';

import {SharedModule} from './shared-module';
import {GlobalRef} from './global-ref';
import {WindowRef} from './window-ref';

const routes: Routes = [
    {path: '', component: IndexComponent},
    {path: 'index.php', component: IndexComponent},
    {path: 'associates', component: AssociatesComponent},
    {path: 'company', component: CompanyComponent},
    {path: 'buying', component: BuyingComponent},
    {path: 'selling', component: SellingComponent},
    {path: 'magazines', component: MagazinesComponent},
  {path: 'presentations', component: PresentationsComponent},
  {path: 'presentations-perm', component: PresentationsPermalinkComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {

    constructor(globals: Globals) {
        //console.log(globals);
    }
}
