import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {Router} from '@angular/router';
import {Headers, Response, RequestOptions} from '@angular/http';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

import { RecaptchaModule } from 'ng-recaptcha';



import {NavigationComponent} from "./navigation.component";
import {FooterComponent} from "./footer.component";

import {SEOService} from './seo.service';

import {SharedModule} from './shared-module';
import {GlobalRef} from './global-ref';
import {BannerImage} from "./banner-image";
import {PreloadImage} from './preload-image.component';

import {Globals} from './globals';

declare var $: any;

@Component({
    selector: 'my-magazines',
    templateUrl: './magazines.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class MagazinesComponent implements AfterViewInit {

    imagesObj: BannerImage[] =[];

    private searchURlStr = '/includes/classes/REP_EvaluationEnquiry.php';  // URL to web api

    public errorStr:string = '';

    public step2Bln: boolean = true;
    model = {
        propertyType : {value: 'Any Type'},
        bedrooms: {value: "Any"},
        bathrooms: {value: "Any"},
        yearBuilt: {value: "All"},
        yearsOwned: {value: ""},
        propertyAddress: {value: ""},
        firstName: {value: ""},
        lastName: {value: ""},
        homeNumber: {value: ""},
        mobileNumber: {value: ""},
        emailAddress: {value: ""}

    };

    constructor(private router: Router,
                private http: HttpClient,
                private SEOService: SEOService,
                private globals: Globals,
                private recaptchaObj: RecaptchaModule,
                _globalRef: GlobalRef,
                private _sanitizer: DomSanitizer) {
        // set the SEO details
        SEOService.setMetaTags({_titleStr: 'Muskoka Luxury Real Estate - Cayman Marshall International Realty Inc. - Magazines', _descriptionStr: 'Search all Luxury Muskoka real estate listings, cottages, homes and land - Muskoka Luxury Listing Agent', _urlStr: 'https://www.caymanmarshall.com/magazines', _keywordsStr: 'lake muskoka luxury real estate, lmuskoka luxury homes, lake muskoka luxury cottages, muskoka luxury property, Lake muskoka Island property, lake muskoka waterfront'});
        // setup the image cycle
        this.setupImageCycle({_imageArr: this.shuffleArray(_globalRef.nativeGlobal.imageArr) });
    }

    ngAfterViewInit (): void  {
        var carouselObj=($('#carousel') as any).carousel({
            interval: 5000
        });
        console.log(carouselObj);

    }

    private setupImageCycle (obj) {
        // define properties
        var imageArr=obj._imageArr;
        // iterate through the image array to add each item to the image object property
        for (var i = 0; i < imageArr.length; i++) {
            // define properties
            var imageObj=imageArr[i];
            var bannerImageObj=new BannerImage();
            bannerImageObj.idStr=i;
            bannerImageObj.nameStr=imageObj._idStr;
            bannerImageObj.valStr="/admin/thumbnail/phpThumb.php?src=/" + imageObj._srcStr + "&w=1900&h=1266"
            this.imagesObj.push(bannerImageObj);
        }
        console.log(this.imagesObj);
    }

    getBackground(image) {
        return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
    }

    submitForm (id: number) {

        switch (id) {
            case 1:
                // show the second part of the search form
                this.step2Bln=true;
                break;
        }
    }

    public submitData (captchaResponse: string) {
        // submit the search data
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded;',
                'X-Requested-With': 'XMLHttpRequest'
            })
        };
        // track the even
        this.http
            .post(this.searchURlStr, $.param(this.setupJSONObject({"_captchaResponse": captchaResponse})), httpOptions)
            .toPromise()
            .then((responseObj) => {
                //console.log(response.json());
                // define properties
                var statusBln=responseObj['statusBln'];
                // fork, if there was an error then output the error
                if (!statusBln){
                    this.errorStr=responseObj['errorArr'][0];
                    // reset the recaptcha
                    let myWindow: any = window;
                    myWindow.grecaptcha.reset();
                } else {
                    if (responseObj['redirectStr']!='') window.location.href=responseObj['redirectStr'] + '&referStr=cm';
                    this.errorStr='Your information has been sent. You will be redirected to our listings shortly.';
                }
                //if (responseObj.redirectStr!='') window.location.href=responseObj.redirectStr + '&referStr=cm';
            })
            .catch(this.handleError);
    }

    setupJSONObject (obj) {
        // define properties
        var captchaResponse=obj._captchaResponse;
        var modelObj=this.model;
        var propertyTypeStr = modelObj.propertyType.value;
        var bedroomsStr = modelObj.bedrooms.value;
        var bathroomsStr = modelObj.bathrooms.value;
        var yearBuiltStr = modelObj.yearBuilt.value;
        var yearsOwnedStr = modelObj.yearsOwned.value;
        var firstNameStr = modelObj.firstName.value;
        var lastNameStr = modelObj.lastName.value;
        var emailAddressStr = modelObj.emailAddress.value;
        var homeNumberStr = modelObj.homeNumber.value;
        var mobileNumberStr = modelObj.mobileNumber.value;
        var propertyAddressStr = modelObj.propertyAddress.value;
        var ipAddrStr = this.globals.ipAddrStr;
        // create the JSON Post Object container
        var JSONObj = {
            "typeOfPropertyStr": propertyTypeStr,
            "bedroomsStr": bedroomsStr,
            "bathroomsStr": bathroomsStr,
            "ageOfBuildingStr": yearBuiltStr,
            "yearsOwnedStr": yearsOwnedStr,
            "firstNameStr": firstNameStr,
            "lastNameStr": lastNameStr,
            "emailStr": emailAddressStr,
            "homePhoneStr": homeNumberStr,
            "mobilePhoneStr": mobileNumberStr,
            "propertyAddressStr": propertyAddressStr,
            "ipAddrStr": ipAddrStr,
            "g-recaptcha-response": captchaResponse

        }
        return JSONObj;
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

    private shuffleArray (array) {
        var m = array.length, t, i;

        // While there remain elements to shuffle
        while (m) {
            // Pick a remaining element�
            i = Math.floor(Math.random() * m--);

            // And swap it with the current element.
            t = array[m];
            array[m] = array[i];
            array[i] = t;
        }

        return array;
    }




}