import {Injectable} from '@angular/core';
import {Headers, Http, Response, RequestOptions} from '@angular/http';

import 'rxjs/add/operator/toPromise';

import {Associate} from './associate';
import {Globals} from './globals';
import {global} from "@angular/core/src/util";



@Injectable()
export class AssociatesService {
  private associatesUrlStr = '/includes/webservices/REP_GetAssociates.php';  // URL to web api

  constructor(private http: Http, private globals: Globals) {
  }

  getAssociates (obj): Promise<Array<Associate>> {
    // define properties
    var typeStr=obj._typeStr;
    let headers = new Headers({
      'Content-Type': 'application/x-www-form-urlencoded;',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let options = new RequestOptions({headers: headers});
    return this.http
      .post(this.associatesUrlStr, $.param({'typeStr': typeStr}), options)
      .toPromise()
      .then((response) => {
        //console.log(response.json());
        var dataObj = response.json();
        var dataArr = dataObj.dataArr;
        console.log(dataArr);
        return dataArr as Associate[];
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
